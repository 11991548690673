import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../../services/notify.service';
import { PaymentHistoryService } from '../../../services/payment-history.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePickerMonthYearComponent } from '../../../users/shared/component/date-picker-month-year/date-picker-month-year.component';
import * as moment from 'moment';
import { toTitleCase } from 'codelyzer/util/utils';
import { DownloadCSVService } from '../../../services/download-csv.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PaymentHistoryComponent implements OnInit {
  paymentHistoryList;
  paymentHistoryListCSV;
  @ViewChild(DatePickerMonthYearComponent, { static: false }) child: DatePickerMonthYearComponent;
  loading = true;
  searchQuery: string = null;
  total: number;
  page = 1;
  limit = 10;
  clearDate = false;
  rawSearchQuery: string;
  headerList = ['Name', 'Package', 'Service Type', 'Date', 'Time', 'Status', 'Amount'];
  advancedSearchForm = new FormGroup({
    q: new FormControl(''),
    payment_month: new FormControl(''),
    service_type: new FormControl(''),
    name: new FormControl('')
  });
  constructor(
    private paymentHistoryService: PaymentHistoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _notify: NotifyService,
    private downloadCSVService: DownloadCSVService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.advancedSearchForm.patchValue({
      q: this.activatedRoute.snapshot.queryParamMap.get('q'),
      payment_month: this.activatedRoute.snapshot.queryParamMap.get('payment_month'),
      service_type: this.activatedRoute.snapshot.queryParamMap.get('service_type'),
      name: this.activatedRoute.snapshot.queryParamMap.get('name')
    });
    this.rawSearchQuery = this.advancedSearchForm.value.q;
    this.hideToggleSearchFilters();
    this.getPaymentHistory();
  }

  goToPage(n: number): void {
    this.page = n;
    this.setUrlParams();
    this.getPaymentHistory();
  }

  onNext(): void {
    this.page++;
    this.setUrlParams();
    this.getPaymentHistory();
  }

  onPrev(): void {
    this.page--;
    this.setUrlParams();
    this.getPaymentHistory();
  }

  getPaymentHistory() {
    this.loading = true;
    const data = this.formatData();
    this.paymentHistoryService.getPaymentHistory(data, this.limit, this.page).subscribe(
      (res: any) => {
        this.paymentHistoryList = res.data;
        this.total = res.size;
      },
      (err) => {
        this.loading = false;
        this.paymentHistoryList.length = 0;
      },
      () => {
        this.loading = false;
      }
    );
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  formatData() {
    const data: any = {
      payment_month: this.advancedSearchForm.value.payment_month
        ? moment(this.advancedSearchForm.value.payment_month).format('YYYY-MM')
        : '',
      service_type: this.advancedSearchForm.value.service_type
        ? this.advancedSearchForm.value.service_type
        : '',
      name: this.advancedSearchForm.value.name ? this.advancedSearchForm.value.name : '',
      q: this.advancedSearchForm.value.q ? this.advancedSearchForm.value.q : ''
    };
    return data;
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      this.setUrlParams();
      this.getPaymentHistory();
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('q').reset();
    this.applyAdvancedSearch();
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'q') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value + ' ';
        } else {
          stringParams =
            stringParams.trim() +
            ' ' +
            entry +
            ': ' +
            this.advancedSearchForm.get(entry).value +
            ';';
        }
      }
    }
    return stringParams;
  }

  setUrlParams() {
    const queryParams = {
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      q: this.advancedSearchForm.get('q').value ? this.advancedSearchForm.get('q').value : null,
      payment_month: this.advancedSearchForm.get('payment_month').value
        ? this.advancedSearchForm.get('payment_month').value
        : null,
      service_type: this.advancedSearchForm.get('service_type').value
        ? this.advancedSearchForm.get('service_type').value
        : null,
      name: this.advancedSearchForm.get('name').value
        ? this.advancedSearchForm.get('name').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = ['q', 'payment_month', 'service_type', 'name'];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      q: queryValue.trim()
    });
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
      this.clearDate = true;
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
      this.clearDate = false;
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }

  resetAdvancedSearchForm() {
    this.page = 1;
    this.advancedSearchForm.patchValue({
      payment_month: null,
      service_type: null,
      name: null
    });
    this.child.clearDate();
    this.setUrlParams();
    this.getPaymentHistory();
  }

  chooseDate($event) {
    this.advancedSearchForm.patchValue({ payment_month: $event.format('YYYY-MM') });
  }

  getColor(payment_status) {
    return payment_status === 'completed' ? 'green-button' : 'pink-button';
  }

  getServiceName(paymentHistory) {
    return paymentHistory.consultation_type
      ? paymentHistory.consultation_type.replace(/_/g, ' ')
      : paymentHistory.product_type
      ? paymentHistory.product_type.replace(/_/g, ' ')
      : '-';
  }

  downloadCSV() {
    const data = this.formatData();
    this.toastr.warning('Your file is being downloaded. Please wait');
    this.paymentHistoryService.getPaymentHistory(data).subscribe(
      (res: any) => {
        this.paymentHistoryListCSV = res.data;
      },
      (err) => {
        this.loading = false;
        this.paymentHistoryList.length = 0;
      },
      () => {
        const paymentHistory = this.formatPaymentHistoryForCSV(this.paymentHistoryListCSV);
        this.downloadCSVService.downloadFile(
          paymentHistory,
          'payment-history-csv',
          this.headerList
        );
      }
    );
  }
  formatPaymentHistoryForCSV(paymentHistoryList) {
    let payment = [];
    paymentHistoryList.forEach((paymentHistory: any) => {
      if (paymentHistory.product) {
        payment.push({
          name: '"' + paymentHistory.user.name + '"',
          package: '"' + paymentHistory.product.title + '"',
          service_type: '"' + this.titleCase(this.getServiceName(paymentHistory)) + '"',
          date: paymentHistory.date,
          time: this.getTime(paymentHistory.created_at, paymentHistory.start_time),
          status: '"' + this.titleCase(paymentHistory.payment_status) + '"',
          amount: paymentHistory.net_total
        });
      }
    });
    return payment;
  }
  titleCase(str) {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  paymentDetail(paymentHistory) {
    this.router.navigate(['payment/history/' + paymentHistory.id]);
  }

  getTime(start_time, created_at) {
    return start_time
      ? moment(start_time, 'hh:mm:ss').format('hh:mm a')
      : moment(created_at).format('hh:mm a');
  }
}
