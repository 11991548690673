import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css', './bootstrap.css']
})
export class AuthComponent implements OnInit {

  constructor(public router: Router) {}

  ngOnInit() {}
}
