import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../../services/notify.service';
import { StaffListService } from '../../../../services/staff-list.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-staff-form',
  templateUrl: './staff-form.component.html',
  styleUrls: ['./staff-form.component.css']
})
export class StaffFormComponent implements OnInit {
  staff: any;
  submitInProgress = false;
  submitted = false;
  staffForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    active: new FormControl(1)
  });
  constructor(
    private toastr: ToastrService,
    private staffService: StaffListService,
    private router: Router,
    private dialogRef: MatDialogRef<StaffFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.staff = data;
  }

  ngOnInit() {
    if (this.staff) {
      this.patchData();
    }
  }

  patchData() {
    this.staffForm.patchValue({
      name: this.staff.name,
      active: this.staff.active
    });
  }
  submit() {
    if (this.staffForm.status === 'VALID') {
      this.submitInProgress = true;
      const id = this.staff ? this.staff.id : null;
      this.staffService.save(this.staffForm.value, id).subscribe(
        (res) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.submitInProgress = false;
        },
        () => {
          this.dialogRef.close(true);
          this.submitInProgress = false;
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
}
