import { ConvertByte } from 'src/app/patients/shared/utility/convertByte.util';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { BannerService } from 'src/app/services/banner.service';
import { FormDataUtil } from 'src/app/shared/utils/formData/form-data-util';

@Component({
  selector: 'app-banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.scss']
})
export class BannerFormComponent implements OnInit {
  banner;
  bannerCategories;
  submitInProgress = false;
  submitted = false;
  bannerForm = new FormGroup({
    image: new FormControl('', [Validators.required]),
    label: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    slug: new FormControl(''),
    status: new FormControl('', [Validators.required])
  });

  selectedFile?: File;

  slugNames = [
    {
      display: 'Consultation',
      slug: 'hah://provider/view'
    },
    {
      display: 'Lab Booking',
      slug: 'hah://lab-booking/create'
    },
    {
      display: 'Buy Medicine( Refill Manually )',
      slug: 'hah://prescription/search'
    },
    {
      display: 'Buy Medicine(Upload Prescription)',
      slug: 'hah://prescription/create/PrescriptionUpload'
    },
    {
      display: 'Vaccine',
      slug: 'hah://vaccine/create'
    },
    {
      display: 'Physiotherapy',
      slug: 'hah://physiotherapy/create'
    },
    {
      display: 'Services',
      slug: 'hah://service/create'
    },
    {
      display: 'PCR Test',
      slug: 'hah://lab-booking/pcr'
    },
    {
      display: 'Covid Assessment form',
      slug: 'hah://covid-assessment'
    }
  ];
  bannerImageError: string;

  constructor(
    private toastr: ToastrService,
    private bannerService: BannerService,
    private dialogRef: MatDialogRef<BannerFormComponent>,
    private convertByte: ConvertByte,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.banner = data;
  }

  ngOnInit() {
    this.patchData();
    this.getBannerCategory();
  }

  storeData() {
    this.submitInProgress = true;
    this.submitted = true;

    if (this.bannerForm.status === 'VALID') {
      const id = this.banner ? this.banner.id : null;
      const requestData = FormDataUtil.convertModelToFormData({
        label: this.bannerForm.controls.label.value,
        banner_category_id: this.bannerForm.controls.category.value,
        link: this.bannerForm.controls.slug.value || undefined,
        active: this.bannerForm.controls.status.value,
        image: this.selectedFile
      });

      this.bannerService.save(requestData, id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
          if (error.error.errors.image) {
            this.bannerImageError = error.error.errors.image;
          }
          this.submitInProgress = false;
        },
        () => {
          this.submitted = false;
          this.dialogRef.close(true);
        }
      );
    }
    else this.submitInProgress = false;
  }

  patchData() {
    if (this.banner) {
      this.bannerForm.patchValue({
        label: this.banner.label,
        image: this.banner.banner_image_link,
        category: this.banner.banner_category_id,
        slug: this.banner.link,
        status: this.banner.active
      });
    }
  }

  uploadImage(event) {
    const files = event.target.files;
    const reader = new FileReader();
    this.bannerImageError = null;

    if (event.target.files && event.target.files.length) {
      this.selectedFile = event.target.files[0];
      reader.onload = () => {
        this.bannerForm.patchValue({
          image: reader.result as string
        });
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  getBannerCategory() {
    this.bannerService.getBannerCategories().subscribe((response: any) => {
      this.bannerCategories = response.data;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
