import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../../services/notify.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ImmunizationNameService } from '../../../../services/immunization-name.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-immunization-form',
  templateUrl: './immunization-form.component.html',
  styleUrls: ['./immunization-form.component.css']
})
export class ImmunizationFormComponent implements OnInit {
  immunization: any;
  submitInProgress = false;
  submitted = false;

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'code',
      error: false
    },
    {
      controlName: 'when_to_give',
      error: false
    },
    {
      controlName: 'dose',
      error: false
    },
    {
      controlName: 'route',
      error: false
    },
    {
      controlName: 'site',
      error: false
    }
  ];

  immunizationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    when_to_give: new FormControl(''),
    dose: new FormControl(''),
    route: new FormControl(''),
    active: new FormControl(1),
    site: new FormControl('')
  });
  constructor(
    private toastr: ToastrService,
    private immunizationService: ImmunizationNameService,
    private router: Router,
    private dialogRef: MatDialogRef<ImmunizationFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.immunization = data;
  }

  ngOnInit() {
    if (this.immunization) {
      this.patchData();
    }
  }

  patchData() {
    this.immunizationForm.patchValue({
      name: this.immunization.name,
      code: this.immunization.code,
      when_to_give: this.immunization.when_to_give,
      dose: this.immunization.dose,
      route: this.immunization.route,
      site: this.immunization.site,
      active: this.immunization.active
    });
  }
  submit() {
    this.submitted = true;
    if (this.immunizationForm.status === 'VALID') {
      this.submitInProgress = true;
      const id = this.immunization ? this.immunization.id : null;
      this.immunizationService.save(this.immunizationForm.value, id).subscribe(
        (res) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
        },
        () => {
          this.submitInProgress = false;
          this.dialogRef.close(true);
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.code) {
      this.setBackendValidationError('code', errors.code[0]);
    } else {
      this.unsetBackendValidationError('code');
    }
    if (errors.when_to_give) {
      this.setBackendValidationError('when_to_give', errors.when_to_give[0]);
    } else {
      this.unsetBackendValidationError('when_to_give');
    }
    if (errors.dose) {
      this.setBackendValidationError('dose', errors.dose[0]);
    } else {
      this.unsetBackendValidationError('dose');
    }
    if (errors.route) {
      this.setBackendValidationError('route', errors.route[0]);
    } else {
      this.unsetBackendValidationError('route');
    }
    if (errors.site) {
      this.setBackendValidationError('site', errors.site[0]);
    } else {
      this.unsetBackendValidationError('site');
    }
  }

  setBackendValidationError(keyName, message) {
    console.log(keyName, message);
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    console.log(this.backendValidationErrors);
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
