import { Component, Input, OnInit } from '@angular/core';
import { MedicationListService } from '../../../../services/medication-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MedicationFormComponent } from '../../medication/medication-form/medication-form.component';

@Component({
  selector: 'app-medication-list',
  templateUrl: './medication-list.component.html',
  styleUrls: ['./medication-list.component.css']
})
export class MedicationListComponent implements OnInit {
  @Input() clearUrl: boolean;
  medication;
  medicationList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  status = 'all';

  medicationSearchQuery: string;

  constructor(
    private medicationService: MedicationListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const medicationSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.medicationSearchQuery = medicationSearchQuery ? medicationSearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getMedication();
  }

  openModal(medication) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '620px';
    dialogConfig.width = '800px';
    dialogConfig.data = medication;

    const dialogRef = this.dialog.open(MedicationFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getMedication();
      }
    });
  }

  getMedication(): void {
    const page = this.page;
    this.loading = true;
    this.medicationService
      .getMedicationList(this.page, this.limit, this.medicationSearchQuery, this.status)
      .subscribe(
        (response: any) => {
          this.medicationList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.medicationList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchMedication();
  }

  searchMedication() {
    this.getMedication();
    this.addParamsToUrl(this.page, this.limit, this.medicationSearchQuery);
  }

  clearSearch() {
    this.medicationList = null;
    this.page = 1;
    this.limit = 10;
    this.medicationSearchQuery = null;
    this.searchMedication();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMedication();
    this.addParamsToUrl(this.page, this.limit, this.medicationSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getMedication();
    this.addParamsToUrl(this.page, this.limit, this.medicationSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getMedication();
    this.addParamsToUrl(this.page, this.limit, this.medicationSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  onMedicationClick(id: number) {
    this.router.navigate(['medication-list/' + id]);
  }

  closeModal(event) {
    this.showModal = false;
  }

  StopPropagation(e: Event) {
    e.stopPropagation();
  }
}
