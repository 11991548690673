import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { VitalService } from '../../../services/vitals.service';
import { LabReportService } from '../../../services/lab-report.service';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { MedicationService } from '../../../services/medication.service';
import { EncounterService } from '../../../services/encounter.service';
import { VitalList } from '../../../shared/model/vitalList';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UrlsService } from '../../../services/urls.service';
import { PageService } from '../../workflow-forms/page.service';
import { TransformUtil } from '../../shared/utility/transform.util';
import * as moment from 'moment';
import { MyAppointmentService } from '../../../services/my-appointment.service';
import { StorageService } from '../../../services/storage.service';
import { UserServiceService } from '../../../services/user-service.service';
import { FilteredInvestigation, Investigation } from '../../../shared/model/investigation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-encounter-create',
  templateUrl: './encounter-create.component.html',
  styleUrls: ['./encounter-create.component.css']
})
export class EncounterCreateComponent implements OnInit {
  patientId: number;
  loggedInUserId: number;
  urlList: Array<string> = [];
  nextUrl: string;
  showInvestigation = false;
  diagnosticCenters: any[] = [];

  bookedTimeList = [];
  scheduleTimeList = [];
  scheduleTimes = [];
  bookedTimeId = [];

  dayName;
  minDate = new Date();

  selectedInvestigations: Array<any> = [];
  suggestedInvestigations: Array<any> = [];
  displaySuggestionList = false;

  investigationKeyword = '';
  hasInvestigations = false;

  INVESTIGATION_SELECT_ALL_MESSAGE = 'Select All';
  INVESTIGATION_DESELECT_ALL_MESSAGE = 'Deselect All';

  DEBOUNCE_TIME = 300;
  investigationSearchInputChanged: Subject<string> = new Subject<string>();

  doctors: string[] = [];
  filteredDoctors: Observable<string[]>;
  diagnosis: string[] = [];
  selectedMedications: Array<any> = [];
  suggestedMedicationList: any;

  medicationTypeList: Array<object> = [];
  consultationTypeList;
  maxDate = new Date();
  encountersForm = new FormGroup({
    date: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    venue: new FormControl('', [Validators.required]),
    nature: new FormControl('', [Validators.required]),
    referred_by: new FormControl('', [Validators.required]),
    chief_complaints: new FormControl('', [Validators.required]),
    examination_details_subjective: new FormControl('', [Validators.required]),
    examination_details_objective: new FormControl('', [Validators.required]),
    examination_details_assessment: new FormControl('', [Validators.required]),
    examination_details_plan: new FormControl('', [Validators.required]),
    diagnosis_date: new FormControl(),
    diagnosed_by: new FormControl(),
    diagnosis_center: new FormControl(),
    clinical_note: new FormControl(),
    referredBy1: new FormControl(),
    advice: new FormControl(),
    drug_allergies: new FormControl(),
    follow_up: new FormControl(),
    follow_up_date: new FormControl(),
    consultation_time_id: new FormControl(),
    consultation_type: new FormControl(),
    lab_id: new FormControl()
    // request_lab: new FormControl()
  });
  vitalList: VitalList[];

  diagnosisList: Array<object> = [];

  showDiagnosisSuggestion: boolean;
  showMedicationSuggestion: boolean;

  primaryIsSelected = true;
  submitted = false;
  // tslint:disable-next-line:no-input-rename
  @Input('ngModel')
  search: any;
  d: Array<object> = [];
  primaryDiagnosis: Array<object> = [];
  secondaryDiagnosis: Array<object> = [];
  arr: Array<any>;
  diagnosisCenters: string[] = [];
  diagnosisCentresFiltered: Observable<object[]>;

  diagnosedByList: string[] = [];
  diagnosedByFiltered: Observable<string[]>;

  medicationSearchKeyword: any;

  referredByFiltered1: Observable<string[]>;
  referredBy1: Array<object> = [];
  medications: Array<object> = [];
  followUpList: any[] = [
    {
      name: 'Yes',
      value: 1
    },
    {
      name: 'No',
      value: 2
    }
  ];
  selectedFollowUp = 2;

  backendValidationErrors: Array<any> = [
    {
      controlName: 'consultation_time_id',
      error: false
    }
  ];
  // For Save and Continue
  saveAndContinueClicked = false;

  venueList: Array<object> = [
    {
      id: 1,
      name: 'Home'
    },
    {
      id: 2,
      name: 'OPD'
    },
    {
      id: 3,
      name: 'Hospital'
    },
    {
      id: 4,
      name: 'Clinic'
    }
  ];

  natureList: Array<object> = [
    {
      id: 1,
      name: 'Emergency'
    },
    {
      id: 2,
      name: 'Urgent'
    },
    {
      id: 3,
      name: 'General'
    }
  ];

  followUp: number;

  // For Form Footer
  showSaveAndContinueButton = true;
  CANCEL_URL = '/patients';
  submitInProgress = false;

  diagnosisSearchInputChanged: Subject<string> = new Subject<string>();

  diagnosisError = false;
  diagnosisErrMsg = '';

  medicationsError = false;
  medicationsErrMsg = '';

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private urlService: UrlsService,
    private vitalService: VitalService,
    private diagnosisService: DiagnosisService,
    private medicationService: MedicationService,
    private encounterService: EncounterService,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil,
    private myAppointmentService: MyAppointmentService,
    private storageService: StorageService,
    private userService: UserServiceService
  ) {}

  ngOnInit() {
    const loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
    this.loggedInUserId = loggedInUser.id;
    this.getSchedule();
    this.getBookedTimeList();
    this.getConsultationType();
    this.getLabList();
    this.initiateInvestigationSearchTimer();
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Encounter Information', this.patientId);
    });

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push(
            children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
          );
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
    this.getVitals();

    this.labReportService.getDoctorList().subscribe((y: any) => {
      this.doctors = y;
      this.filteredDoctors = this.setACField(this.encountersForm.controls.referred_by, 'doctors');
      this.referredBy1 = y;
      this.referredByFiltered1 = this.setACField(
        this.encountersForm.controls.referredBy1,
        'referredBy1'
      );
    });

    this.diagnosisService.getDiagnosisListAll(this.patientId).subscribe((y: any) => {
      this.diagnosisList = y.data;
      console.log(this.diagnosisList);
    });
    // get diagnosed by list from api and assign
    this.labReportService.getDoctorList().subscribe((y: any) => {
      this.diagnosedByList = y;
      this.diagnosedByFiltered = this.setACField(
        this.encountersForm.controls.diagnosed_by,
        'diagnosedByList'
      );
    });

    // get diagnosis center list from api and assign
    this.labReportService.getLabList().subscribe((y: any) => {
      this.diagnosisCenters = y.data.map(lab => lab.name);
      this.diagnosisCentresFiltered = this.setACField(
        this.encountersForm.controls.diagnosis_center,
        'diagnosisCenters'
      );
    });
    this.initiateDiagnosisSearchTimer();
    this.getMedicationTypeList();
  }
  changeDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getMedicationTypeList() {
    this.encounterService.getMedicationTypeList().subscribe(
      (response: any) => {
        this.medicationTypeList = response.data;
      },
      (error) => {
        this.medicationTypeList.length = 0;
      }
    );
  }

  initiateDiagnosisSearchTimer() {
    this.diagnosisSearchInputChanged.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((search) => {
      this.diagnosisService.getDiagnosisList(this.search, this.d).subscribe((x: any) => {
        this.arr = x;
        this.showDiagnosisSuggestion = true;
      });
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getMergedDateTime(date, time) {
    const utcDate = date ? moment.utc(date + ' ' + time, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD HH:mm:ss') : undefined;
    return utcDate;
  }

  addEncounters() {
    this.vitalList.map((vital) => {
      if (vital.secondary_label === 'bp_systolic') {
        this.encountersForm.controls[vital.label].setErrors(null);
      }
      if (vital.secondary_label === 'bp_diastolic') {
        this.encountersForm.controls[vital.secondary_label].setErrors(null);
      }
    });

    const formFields: string[] = [
      'diagnosis_date',
      'diagnosed_by',
      'diagnosis_center',
      'referredBy1'
    ];
    for (var val of formFields) {
      console.log(val);
      if (this.encountersForm.controls[val].invalid) {
        this.encountersForm.controls[val].setErrors(null);
      }
    }
    this.medicationsError = false;
    this.diagnosisError = false;

    this.submitted = true;
    this.submitInProgress = true;
    console.log(this.encountersForm.errors);
    if (this.encountersForm.invalid) {
      this.toastr.error(
        "Sorry, Encounters couldn't be added at the moment because some fields are missing!"
      );
      this.submitInProgress = false;
      return;
    }
    const date = this.encountersForm.controls.date.value;
    const time = this.encountersForm.controls.time.value;
    console.log(date, time);
    const data = this.encountersForm.value;

    data.date = this.getMergedDateTime(date, time);
    data.diagnosis = this.d;
    data.medications = this.selectedMedications;
    if (this.selectedInvestigations) {
      data.investigations = this.getSanitizedBookings(this.selectedInvestigations);
    }
    if (data.follow_up_date) {
      data.follow_up_date = moment(data.follow_up_date).format('YYYY-MM-DD');
    }
    console.log('send data:' + data);
    this.encounterService.store(data, this.patientId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.submitInProgress = false;
        if (error.status === 422) {
          const errors = error.error.errors;
          this.setBackendValidationErrors(errors);
          for (const err in error.error.errors) {
            console.log(error.error.errors[err]);
            if (err === 'diagnosis') {
              this.diagnosisError = true;
              this.diagnosisErrMsg = error.error.errors[err];
            } else if (err === 'medications') {
              this.medicationsError = true;
              this.medicationsErrMsg = error.error.errors[err];
            } else {
              this.encountersForm.controls[err].setErrors({
                invalid: true,
                msg: error.error.errors[err]
              });
            }
          }
        }
        this.toastr.error(error.error.message);
      },
      () => {
        this.submitInProgress = false;
        this.router.navigate(['/patients/' + this.patientId]);
        this.saveAndContinueClicked = false;
      }
    );
  }
  clearInvestigation() {
    this.showInvestigation = true;
    this.selectedInvestigations = [];
  }
  followUpChange(event: MatRadioChange): void {
    this.followUp = event.value;
    if (this.followUp === 1) {
      this.encountersForm.get('follow_up_date').setValidators([Validators.required]);
      this.encountersForm.get('consultation_time_id').setValidators([Validators.required]);
      this.encountersForm.get('consultation_type').setValidators([Validators.required]);
    } else {
      this.encountersForm.get('follow_up_date').clearValidators();
      this.encountersForm.get('follow_up_date').updateValueAndValidity();
      this.encountersForm.get('consultation_time_id').clearValidators();
      this.encountersForm.get('consultation_time_id').updateValueAndValidity();
      this.encountersForm.get('consultation_type').clearValidators();
      this.encountersForm.get('consultation_type').updateValueAndValidity();
    }
  }

  getVitals(): void {
    this.vitalService.getVitalList('', 'encounter').subscribe((vitalList) => {
      console.log(vitalList);
      // @ts-ignore
      vitalList.map((vital) => {
        if (typeof vital.hasOwnProperty('rules') !== undefined) {
          const rulesMin = vital.rules.split('|')[0];
          const MinValue = rulesMin.split(':')[1];
          const rulesMax = vital.rules.split('|')[1];
          const MaxValue = rulesMax.split(':')[1];
          this.encountersForm.addControl(
            vital.label,
            new FormControl('', [
              Validators.min(this.transform.toNumber(MinValue)),
              Validators.max(this.transform.toNumber(MaxValue))
            ])
          );
        }
        if (vital.secondary_rules !== null) {
          const rulesMinSecondary = vital.secondary_rules.split('|')[0];
          const MinValueSecondary = rulesMinSecondary.split(':')[1];
          const rulesMaxSecondary = vital.secondary_rules.split('|')[1];
          const MaxValueSecondary = rulesMaxSecondary.split(':')[1];
          this.encountersForm.addControl(
            vital.secondary_label,
            new FormControl('', [
              Validators.min(this.transform.toNumber(MinValueSecondary)),
              Validators.max(this.transform.toNumber(MaxValueSecondary))
            ])
          );
        } else if (vital.secondary_label != null) {
          this.encountersForm.addControl(vital.secondary_label, new FormControl(''));
        }
        if (vital.secondary_options != null) {
          this.encountersForm
            .get(vital.secondary_label)
            .setValue(vital.secondary_options.split(',')[0]);
        }
        this.encountersForm.addControl(
          vital.uom_label.concat('_' + vital.label),
          new FormControl()
        );
        this.encountersForm
          .get(vital.uom_label.concat('_' + vital.label))
          .setValue(vital.uom_option.split(',')[0]);
      });
      this.vitalList = vitalList;
    });
  }
  getSanitizedBookings(selectedInvestigations: Array<any>) {
    const investigations = [];
    selectedInvestigations.forEach((investigation: any) => {
      investigations.push({
        id: investigation.id,
        name: investigation.name,
        selected: true,
        tests: investigation.tests.filter((test) => test.selected === true)
      });
    });
    return investigations;
  }
  showSuggestions() {
    if (this.search.length <= 2) {
      this.showDiagnosisSuggestion = false;
      return false;
    }
    this.diagnosisSearchInputChanged.next(this.search);
    return true;
  }

  selectSuggestion(id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });
    x.active = true;
    // added by sishir start
    if (this.primaryIsSelected === true) {
      x.primary = true;
      this.primaryDiagnosis.push(x);
    } else {
      x.primary = false;
      this.secondaryDiagnosis.push(x);
    }

    this.showDiagnosisSuggestion = false;
    this.search = '';
    this.d.push(x);
    this.diagnosisError = false;
  }

  toggleDiagnosisActive(x) {
    if ('active' in x && x.active === true) {
      x.active = false;
    } else {
      x.active = true;
    }
    console.log(x);
  }

  togglePrimaryDiagnosis(e): void {
    this.primaryIsSelected = e.value === 'primary';
  }

  unSelectDiagnosis(x): void {
    // console.log(x);
    this.d = this.d.filter((y) => {
      return y !== x;
    });

    if (x.primary === true) {
      this.primaryDiagnosis = this.primaryDiagnosis.filter((y) => {
        return y !== x;
      });
    } else {
      this.secondaryDiagnosis = this.secondaryDiagnosis.filter((y) => {
        return y !== x;
      });
    }
  }

  showMedicationSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.medicationService
        .getMedicationList(keyword, this.getArrayOfIdObjects(this.selectedMedications))
        .subscribe((x) => {
          this.suggestedMedicationList = x;
          this.showMedicationSuggestion = true;
        });
    } else {
      this.showMedicationSuggestion = false;
    }
  }

  getArrayOfIdObjects(objectList: Array<any>): Array<any> {
    const returnObjectList = [];
    objectList.forEach((objectSingle: any) => {
      if (objectSingle.id) {
        returnObjectList.push({ id: objectSingle.id });
      }
    });
    return returnObjectList;
  }

  createMedicationInstance() {
    const newMedication = {
      name: '',
      quantity: '',
      frequency: '',
      duration: '',
      way_of_intake: '',
      editMode: true,
      state: 'expanded',
      expandIcon: 'angle-up',
      expandMessage: 'Collapse Details'
    };
    this.selectedMedications.unshift(newMedication);
    this.showMedicationSuggestion = false;
    this.medicationSearchKeyword = '';
  }

  selectMedication(id) {
    const x = this.suggestedMedicationList.find((y) => {
      return y.id === id;
    });
    x.state = 'expanded';
    x.expandIcon = 'angle-up';
    x.expandMessage = 'Collapse Details';
    x.editMode = false;
    this.showMedicationSuggestion = false;
    this.medicationSearchKeyword = '';
    this.selectedMedications.unshift(x);
    this.medicationsError = false;
  }

  setQuantity(e, x): void {
    x.quantity = e.target.value;
  }

  setFrequency(e, x): void {
    x.frequency = e.target.value;
  }

  setDuration(e, x): void {
    x.duration = e.target.value;
  }

  setUom(e, x): void {
    x.uom = e.target.value;
  }

  setDiagnosis(e, x): void {
    x.diagnosis = e.value;
  }

  setType(e, x): void {
    x.type = e.value;
    const typeObject: any = this.medicationTypeList.find((type: any) => {
      return type.name === e.value;
    });
    x.way_of_intake = typeObject.way_of_intake;
  }

  deleteMedication(medication): void {
    this.selectedMedications = this.selectedMedications.filter((y) => {
      return y !== medication;
    });
  }

  invalidMedicationField(medication, field) {
    const fieldList = ['name', 'uom', 'type'];
    if (fieldList.includes(field)) {
      console.log(medication[field]);
      return (
        medication[field] === '' || medication[field] === null || medication[field] === undefined
      );
    }
  }

  toggleState(medication): void {
    medication.state = medication.state === 'collapsed' ? 'expanded' : 'collapsed';
    if (medication.state === 'expanded') {
      medication.expandIcon = 'angle-up';
      medication.expandMessage = 'Collapse Details';
    } else {
      medication.expandIcon = 'angle-down';
      medication.expandMessage = 'Expand Details';
    }
  }

  getScheduleData(date) {
    this.dayName = moment().weekday(moment(date).weekday()).format('dddd').toLowerCase();
    this.getScheduleId();
    this.getBookedId(date);
    this.getAvailableDate();
  }

  getAvailableDate() {
    this.bookedTimeId.forEach((bookedId) => {
      this.scheduleTimes = this.scheduleTimes.filter((scheduleTime) => {
        return scheduleTime.consultation_time_id !== bookedId;
      });
    });
  }

  getSchedule() {
    this.myAppointmentService.getScheduleTimeList(this.loggedInUserId).subscribe(
      (res: any) => {
        this.scheduleTimeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }
  getScheduleId() {
    this.scheduleTimes = [];
    this.scheduleTimeList.forEach((bookedTime) => {
      if (this.dayName === bookedTime.day) {
        this.scheduleTimes.push(bookedTime);
      }
    });
  }

  getBookedTimeList() {
    this.myAppointmentService.getBookedTimeList(this.loggedInUserId).subscribe(
      (res: any) => {
        this.bookedTimeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  getBookedId(date) {
    this.bookedTimeId = [];
    this.bookedTimeList.forEach((bookedTime) => {
      if (
        moment(date).format('YYYY-MM-DD') === bookedTime.date &&
        bookedTime.consultation_status === 'active'
      ) {
        this.bookedTimeId.push(bookedTime.consultation_time_id);
      }
    });
  }

  getConsultationType() {
    this.userService.getProfile().subscribe(
      (response: any) => {
        this.consultationTypeList = response.consultation_types;
      },
      (error) => {},
      () => {}
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabList().subscribe(
      (y: any) => {
        this.diagnosticCenters = y.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }
  getLabId(diagnosticName) {
    const obj = this.diagnosticCenters.find(
      (diagnosticCenter) => diagnosticCenter.name.toLowerCase() === diagnosticName.toLowerCase()
    );
    return obj.id;
  }

  showSuggestionsInvestigation() {
    if (this.investigationKeyword.length <= 2) {
      this.displaySuggestionList = false;
      return;
    }
    this.investigationSearchInputChanged.next(this.investigationKeyword);
    return true;
  }

  selectInvestigation(investigation) {
    console.log(investigation);
    investigation.tests = [];
    investigation.note = '';
    this.selectedInvestigations.push(investigation);

    this.displaySuggestionList = false;
    this.investigationKeyword = '';

    this.labReportService.getTestTypeServiceList(investigation.id).subscribe((testTypes: any) => {
      testTypes.forEach((testType) => {
        testType.selected = true;
        investigation.tests.push(testType);
      });
    });
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  getIdArrayOfObjects(arrayOfObjects: Array<any>) {
    const arr = [];
    arrayOfObjects.forEach((obj: any) => {
      arr.push({
        id: obj.id
      });
    });
    return arr;
  }

  areAllTestSelected(investigation: any) {
    const totalTests = investigation.tests.length;
    const selectedTests = investigation.tests.filter((test: any) => test.selected).length;
    return selectedTests === totalTests;
  }

  toggleSelectAllInvestigation(investigation) {
    if (!this.areAllTestSelected(investigation)) {
      investigation.tests.map((test) => (test.selected = true));
      investigation.selectedMessage = this.INVESTIGATION_DESELECT_ALL_MESSAGE;
    } else {
      investigation.tests.map((test) => (test.selected = false));
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
    }
  }

  removeInvestigation(investigation) {
    this.selectedInvestigations = this.selectedInvestigations.filter((selectedInvestigation) => {
      return (
        this.selectedInvestigations.indexOf(investigation) !==
        this.selectedInvestigations.indexOf(selectedInvestigation)
      );
    });
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  initiateInvestigationSearchTimer() {
    this.investigationSearchInputChanged
      .pipe(debounceTime(this.DEBOUNCE_TIME))
      .subscribe((search) => {
        this.labReportService
          .getInvestigationServiceList(
            this.encountersForm.controls.lab_id.value,
            this.investigationKeyword,
            this.selectedInvestigations
          )
          .subscribe((investigations: Investigation[]) => {
            console.log('investigation api response', investigations);
            this.suggestedInvestigations = this.getProcessedSuggestedInvestigations(investigations);
            console.log('investigation filtered response', this.suggestedInvestigations);
            this.displaySuggestionList = true;
          });
      });
  }

  getProcessedSuggestedInvestigations(investigations: Investigation[]) {
    const filteredResult: FilteredInvestigation[] = [];
    investigations = this.renameTestKey(investigations);
    investigations.forEach((investigation) => {
      if (
        investigation.tests.length <= 0 ||
        investigation.tests.filter((test) => test.found === true).length <= 0
      ) {
        // if keyword matches investigation,
        // return instance of investigation

        filteredResult.push(investigation);
      } else {
        // if keyword matches test type,
        // filter matched test type and
        // return instance of investigation
        // with test type

        const matchedTestResults = investigation.tests.filter((test) => test.found);

        matchedTestResults.forEach((test) => {
          const matchedInvestigation: FilteredInvestigation = { ...investigation };
          matchedInvestigation.test_type_suggested = test;
          filteredResult.push(matchedInvestigation);
        });
      }
    });
    return filteredResult;
  }
  renameTestKey(investigations) {
    // renamed test key from test_type_list to tests
    // this implementation can be removed once
    // test key is returned as tests from API

    return investigations.map((investigation) => {
      investigation.tests = investigation.test_type_list;
      delete investigation.test_type_list;
      return investigation;
    });
  }

  toggleSelectTest(investigation, test) {
    test.selected = !test.selected;
    this.setInvestigationCheckedStatus(investigation);
  }

  setInvestigationCheckedStatus(investigation: any) {
    const totalTests = investigation.tests.length;
    const selectedTests = investigation.tests.filter((test: any) => test.selected).length;
    if (this.areAllTestSelected(investigation)) {
      investigation.selectedMessage = this.INVESTIGATION_DESELECT_ALL_MESSAGE;
      investigation.indeterminate = false;
      return;
    }
    if (selectedTests === 0) {
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
      investigation.indeterminate = false;
      return;
    }
    if (selectedTests <= totalTests) {
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
      investigation.indeterminate = true;
      return;
    }
  }
  setBackendValidationErrors(errors) {
    this.backendValidationErrors.forEach((keyObject) => {
      const errorArray  = errors[keyObject.controlName];
      const errorMessage = errorArray && errorArray.length > 0 ? errorArray[0] : '';
      keyObject.error = !!errorMessage;
      keyObject.message = errorMessage || '';
    });
  }
  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
