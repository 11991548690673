import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { UserServiceService } from '../services/user-service.service';
import { Profile } from '../shared/model/profile';
import { User } from '../shared/model/user';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { ModalConstant } from '../shared/components/app-modal-dialog/constant/modal-constants';
import { ModalIcon } from '../shared/enums/modal-icon';
import { CallService } from '../video-chat/service/call.service';
import { ToastMessageService } from '../shared/services/toast-message-service/toast-message.service';
import { SharedConstant } from '../shared/constant/shared-constant';
import { StorageService } from '../services/storage.service';
import { BrowserNotificationService } from '../shared/services/browser-notification.service';
import { AuthService } from '../auth/shared/services/auth.service';
import { SocketService, UserStatus, VoIPStatus } from '../services/socket.service';
import { VideoChatService } from '../services/video-chat.service';
import { Observable } from 'rxjs';

interface CallEmitterType {
  type: string;
  value?: boolean;
  data?: any;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('userInfo', { static: false }) userInfoDiv: ElementRef;
  profiles: Profile[];
  actions: Array<any>;
  user: User;
  userStatus?: UserStatus;
  parentMenu: any;
  formButtonStatus: boolean;
  openModal: boolean;
  openVideoModal: boolean;
  modalConstant = ModalConstant;
  modalIcon = ModalIcon;
  callerName: string;
  ringingSound: any;
  audio = new Audio();
  @Input() sidebarCollapsed = false;
  sharedConstant = SharedConstant;
  userAuthorized: boolean;
  invite;

  localInviteBroadCastChannel: BroadcastChannel = new BroadcastChannel('call_invite');

  constructor(
    private profileService: ProfileService,
    private userService: UserServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private callService: CallService,
    private toastMessageService: ToastMessageService,
    private storageService: StorageService,
    private browserNotification: BrowserNotificationService,
    private authService: AuthService,
    private socketService: SocketService,
    private videoChatService: VideoChatService,
    private serializer: UrlSerializer
  ) {}

  ngOnDestroy(): void {
    this.localInviteBroadCastChannel.close();
  }

  ngOnInit() {
    this.getActions();
    this.getUser();
    this.openModal = false;
    this.openVideoModal = false;
    this.browserNotification.requestPermission();
    this.socketService.setUpSocketConnection();
    this.socketService.listen(SocketService.events.EVENT_ROOM_INVITE, (args) => {
      this.invite = args[0];
      this.socketRoomEventHandler(this.invite);
    });
    this.socketService.addOnConnectionListener((connected) => {
      this.userAuthorized = connected;
    });
    this.socketService.connect();

    // Todo remove after twilio video implementation
    // this.authService.userAuthEmitter.subscribe((userAuthorized) => {
    //   this.userAuthorized = userAuthorized;
    // });
    this.localInviteBroadCastChannel.onmessage = (i) => {
      this.audio.pause();
      this.openModal = false;
    };
  }

  listenUserStatus(id: number) {
    this.socketService.roomService.onUserStatus(id, (status) => {
      this.userStatus = status;
    });
  }

  socketRoomEventHandler(invite) {
    switch (invite.callType) {
      case 'INCOMING_CALL': {
        if (this.userStatus && this.userStatus.status !== VoIPStatus.AVAILABLE) {
          break;
        }
        if (!this.router.url.split('?').includes('/call')) {
          this.openModal = true;
          this.openVideoModal = true;
          this.openCallInvite(this.invite);
        }
        break;
      }
      case 'CALL_IGNORED':
        this.audio.pause();
        this.openModal = false;
        this.openVideoModal = false;
        break;
      case 'CALL_REJECTED':
        this.audio.pause();
        this.openModal = false;
        this.videoChatService.setCallRejectedStatus(true);
    }
  }

  ngAfterViewInit() {
    this.shouldClickItself();
  }

  getActions(): void {
    this.profileService.getActions().subscribe((actions) => (this.actions = actions));
  }

  getUser(): void {
    let data;
    this.userService.getUser().subscribe(
      (user) => {
        data = user;
      },
      (err) => {
        if (err.status === 401) {
          //this.storageService.clear();
          //window.location.href = 'login';
        }
      },
      () => {
        this.getProfile(data);
        this.listenUserStatus(data.id);
      }
    );
  }
  getProfile(user): void {
    this.userService.getProfile().subscribe(
      (profile) => {
        this.setUserToStorage(user, profile);
      },
      (err) => {
        if (err.status === 401) {
          //this.storageService.clear();
          //window.location.href = 'login';
        }
      },
      () => {
        this.user = JSON.parse(this.storageService.getLoggedInUser());
        this.getParentMenu(this.user.profile_id);
      }
    );
  }
  getParentMenu(profileId): void {
    this.profileService.getParentMenu(profileId).subscribe(
      (parentMenu) => {
        this.parentMenu = parentMenu;
      },
      (error) => {
        console.log('Could not get sidebar menu at the moment.');
      },
      () => {
        if (this.router.url === '/' || this.router.url === '/dashboard') {
          this.router.navigate([this.parentMenu[0].url]);
        }
      }
    );
  }

  setUserToStorage(userObj, profileObj) {
    this.storageService.setLoggedInUser(
      JSON.stringify({
        id: userObj.id,
        name: userObj.name,
        email: userObj.email,
        profile_id: userObj.profile_id,
        profile_image: profileObj.profile_image
      })
    );
  }

  toggleMenuHide(event) {
    event.preventDefault();
    const body = document.getElementsByTagName('body')[0];
    if (window.innerWidth < 992) {
      if (body.classList.contains('sidebar-open')) {
        body.classList.toggle('sidebar-collapse');
        body.classList.toggle('sidebar-open');
      }
    }
  }

  /**
   * This method retrieves accept/rejec option value for modal dialog
   * @param acceptClicked This is the first paramater that accepts model true/false value
   */

  confirmDialog(acceptClicked: boolean) {
    // if (acceptClicked) {
    //   this.audio.pause();
    //   this.router.navigate(['/', ACCEPTCALL]);
    // } else if (!acceptClicked) {
    //   this.audio.pause();
    //   this.callService.rejectCall();
    //   this.onCallRejectSound();
    //   this.toastMessageService.setMessage(this.sharedConstant.info, 'You Have Reject Call');
    // }
    // this.openModal = false;
    if (acceptClicked) {
      this.audio.pause();
      const acceptInvite = {
        ...this.invite,
        callType: 'CALL_ACCEPTED'
      };
      this.socketService.emit(SocketService.events.EVENT_ROOM_IO_INVITE, acceptInvite);
      const url = this.router.createUrlTree(['/call'], {
        queryParams: {
          appointment_id: this.invite.additionalParams.appointment_id
        }
      });
      window.open(this.serializer.serialize(url));
      this.localInviteBroadCastChannel.postMessage(acceptInvite);
    } else if (!acceptClicked) {
      console.log('Check call rejected -->', this.invite)
      this.audio.pause();
      const rejectInvite = {
        ...this.invite,
        callType: 'CALL_IGNORED'
      };
      console.log('Check call rejected 2 -->', rejectInvite)
      //this.callService.rejectCall();
      this.onCallRejectSound();
      this.socketService.emit(SocketService.events.EVENT_ROOM_IO_INVITE, rejectInvite);
      this.toastMessageService.setMessage(this.sharedConstant.info, 'You Have Reject Call');
      this.localInviteBroadCastChannel.postMessage(rejectInvite);
    }
    this.openModal = false;
  }

  openCallInvite(invite): void {
    console.log('HAH: OPEN model onCallListener in', invite);
    this.openModal = true;
    this.callerName = invite.initiator.name;
    this.onCallRingingSound();
    setTimeout(() => {
      this.audio.pause();
      this.openModal = false;
    }, 60000);
  }
  shouldOpenCallModal(): void {
    this.callService.callDialogEmitter.subscribe((payload: CallEmitterType) => {
      switch (payload.type) {
        case 'onCallListener':
          console.log('HAH: OPEN model onCallListener in', payload);
          this.openModal = true;
          this.callerName = payload.data.userName;
          this.onCallRingingSound();
          break;
        default:
          console.log('HAH: OPEN model onCallListener in default');
          this.openModal = false;
          this.audio.pause();
          this.onCallRejectSound();
          break;
      }
    });
  }

  shouldClickItself() {
    this.callService.callEmitter.subscribe((payload: CallEmitterType) => {
      switch (payload.type) {
        case 'onSessionCloseListener':
          if (this.userInfoDiv.nativeElement) {
            this.userInfoDiv.nativeElement.click();
          }
          break;
        default:
          break;
      }
    });
  }

  onCallRejectSound(): void {
    this.audio.src = '../../assets/audio/video-chat/end_call.mp3';
    this.audio.loop = false;
    this.audio.load();
    this.audio.play();
  }

  onCallRingingSound(): void {
    this.audio.src = '../../assets/audio/video-chat/calling.mp3';
    this.audio.loop = true;
    this.audio.load();
    this.audio.play();
  }

  checkIfUrlIncludes(urlKeyword) {
    return this.router.url.includes(urlKeyword);
  }
}
