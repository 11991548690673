import { Component, OnInit } from '@angular/core';
import { MyAppointmentService } from '../../../services/my-appointment.service';
import { StorageService } from '../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-my-appointment-list',
  templateUrl: './my-appointment-list.component.html',
  styleUrls: ['./my-appointment-list.component.css']
})
export class MyAppointmentListComponent implements OnInit {
  appointmentTypeList = [
    {
      name: 'Upcoming',
      slug: 'upcoming',
      request: ''
    },
    {
      name: 'Instant Call',
      slug: 'instant_call',
      request: 'instant_call'
    },
    {
      name: 'Completed',
      slug: 'completed',
      request: 'history'
    },
    {
      name: 'Total',
      slug: 'total',
      request: 'total'
    }
  ];
  appointmentUserType = 'doctor';
  loading = true;
  userId: string;
  myAppointmentList = [];
  type = 'upcoming';
  request = '';
  page = 1;
  limit = 10;
  total: number;
  constructor(
    private myAppointmentService: MyAppointmentService,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    // const appointmentUserType = this.activatedRoute.snapshot.queryParamMap.get('appointment');

    const appointmentType = this.activatedRoute.snapshot.queryParamMap.get('appointment_type');
    const selectedType = this.appointmentTypeList.find((it) => it.slug === appointmentType);
    this.type = selectedType ? selectedType.slug : this.type;
    this.request = selectedType ? selectedType.request : this.request;
    this.appointmentUserType = this.router.url.includes('nurse-appointment') ? 'nurse' : 'doctor';

    this.appointmentTypeList = this.appointmentTypeList.filter(it => {
      return it.slug === 'instant_call' ? environment.enableVoIP && this.appointmentUserType === 'doctor' : true;
    });

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    const loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
    this.userId = loggedInUser.id;
    this.getMyAppointment();
  }

  getMyAppointment() {
    this.loading = true;
    this.addParamsToUrl(this.page, this.limit, this.type, this.appointmentUserType);
    this.myAppointmentService
      .getAppointmentList(this.page, this.limit, this.userId, this.request, this.appointmentUserType)
      .subscribe(
        (res: any) => {
          this.total = res.size;
          this.myAppointmentList = res.data;
        },
        (error) => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  getData(type) {
    this.type = type.slug;
    this.request = type.request;
    this.getMyAppointment();
  }

  goToAvailability() {
    this.router.navigate(['my-schedule']);
  }

  cancelConsultation() {
    this.getMyAppointment();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMyAppointment();
  }

  onNext(): void {
    this.page++;
    this.getMyAppointment();
  }

  onPrev(): void {
    this.page--;
    this.getMyAppointment();
  }

  addParamsToUrl(pageNo, limit, appointment_type, appointment_user_type) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        appointment_type,
        appointment: appointment_user_type
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
