import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-create',
  styleUrls: ['./profile-create.component.css'],
  templateUrl: './profile-create.component.html'
})
export class ProfileCreateComponent implements OnInit {
  submitted = false;
  maxNameLength = 255;
  maxDescriptionLength = 65535;
  isServiceProviderStatus = false;

  profileForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(this.maxNameLength)]),
    description: new FormControl('', [Validators.maxLength(this.maxDescriptionLength)]),
    profile_type: new FormControl('', [Validators.required]),
    theme: new FormControl('red', [Validators.required])
  });

  is_connectycube = false;
  is_nurse = false;
  is_physiotherapist = false;
  profile: object;

  profileTypes = [
    {
      slug: 'patient_representative',
      name: 'Patient Representative'
    },
    {
      slug: 'service_provider',
      name: 'Service Provider'
    }
  ];

  arr: Array<any> = [];
  nodes: Array<any>;

  // For Form Footer
  cancelUrl = '/profiles';
  submitInProgress = false;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getActions();
  }

  getActions(): void {
    this.submitInProgress = true;
    this.profileService.getActions().subscribe(
      (actions) => {
        this.nodes = actions;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  check(v): void {
    v.checked = true;
    console.log(v);
  }

  uncheck() {
    console.log('aaaa');
  }

  submit() {
    this.submitted = true;
    this.submitInProgress = true;
    this.setSelectedPerms(); // assign selected perms to this.arr
    if (this.profileForm.status === 'VALID' && this.arr.length >= 1) {
      const data = this.profileForm.value;
      data.is_connectycube = this.is_connectycube ? 1 : 0;
      data.is_nurse = this.is_nurse ? 1 : 0;
      data.is_physiotherapist = this.is_physiotherapist ? 1 : 0;
      data.perms = this.arr; // this.arr assigned from this.setSelectedPerms();

      this.profileService.add(data).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.toastr.success(response.message);
          }
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['profiles']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setSelectedPerms() {
    this.arr = [];
    this.nodes.map((x) => {
      // console.log(x.id);
      if ('checked' in x && x.checked) {
        console.log(x.name + ' checked');
        this.arr.push(x.id);
      }
      if (x.children.length > 0) {
        x.children.map((y) => {
          if ('checked' in y && y.checked) {
            console.log(y.name + ' checked');
            this.arr.push(y.id);
          }
          // console.log(y.id);
          if (y.children.length > 0) {
            this.getValuesFormChildren(y);
          }
        });
      }
    });
  }

  getValuesFormChildren(z) {
    if ('checked' in z && z.checked) {
      console.log(z.name + ' checked');
      this.arr.push(z.id);
    }
    if (z.children.length > 0) {
      z.children.map((a) => {
        this.getValuesFormChildren(a);
      });
    }
  }

  isServiceProvider() {
    this.isServiceProviderStatus =
      this.profileForm.controls.profile_type.value === 'service_provider' ? true : false;
  }
}
