import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { VitalService } from '../../../services/vitals.service';
import { LabReportService } from '../../../services/lab-report.service';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { MedicationService } from '../../../services/medication.service';
import { EncounterService } from '../../../services/encounter.service';
import { VitalList } from '../../../shared/model/vitalList';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UrlsService } from '../../../services/urls.service';
import * as moment from 'moment';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { TransformUtil } from '../../shared/utility/transform.util';
import { MyAppointmentService } from '../../../services/my-appointment.service';
import { StorageService } from '../../../services/storage.service';
import { UserServiceService } from '../../../services/user-service.service';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-encounter-edit',
  templateUrl: './encounter-edit.component.html',
  styleUrls: ['./encounter-edit.component.css']
})
export class EncounterEditComponent implements OnInit {
  patientId: number;
  encounterId: number;
  patientEncounter: any;
  urlList: Array<string> = [];
  nextUrl: string;

  loggedInUserId: number;
  bookedTimeList = [];
  scheduleTimeList = [];
  scheduleTimes = [];
  bookedTimeId = [];

  dayName;
  minDate = new Date('YYYY-MM-DD');

  doctors: string[] = [];
  filteredDoctors: Observable<string[]>;
  diagnosis: string[] = [];
  selectedMedications: Array<any> = [];
  medicationList: any;
  consultationTypeList;
  maxDate = new Date();
  encountersForm = new FormGroup({
    date: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    venue: new FormControl('', [Validators.required]),
    nature: new FormControl('', [Validators.required]),
    referred_by: new FormControl('', [Validators.required]),
    chief_complaints: new FormControl('', [Validators.required]),
    examination_details_subjective: new FormControl('', [Validators.required]),
    examination_details_objective: new FormControl('', [Validators.required]),
    examination_details_assessment: new FormControl('', [Validators.required]),
    examination_details_plan: new FormControl('', [Validators.required]),
    diagnosis_date: new FormControl(),
    diagnosed_by: new FormControl(),
    diagnosis_center: new FormControl(),
    clinical_note: new FormControl(),
    referredBy1: new FormControl(),
    advice: new FormControl(),
    drug_allergies: new FormControl(),
    follow_up: new FormControl(),
    follow_up_date: new FormControl(),
    consultation_time_id: new FormControl(),
    consultation_type: new FormControl(),
    request_lab: new FormControl()
  });
  vitalList: VitalList[];

  diagnosisList: Array<object> = [];

  showDiagnosisSuggestion: boolean;
  showMedicationSuggestion: boolean;

  primaryIsSelected = true;
  submitted = false;
  // tslint:disable-next-line:no-input-rename
  @Input('ngModel')
  search: any;
  d: Array<object> = [];
  primaryDiagnosis: Array<object> = [];
  secondaryDiagnosis: Array<object> = [];
  arr: Array<any>;
  diagnosisCenters: string[] = [];
  diagnosisCentresFiltered: Observable<object[]>;

  diagnosedByList: string[] = [];
  diagnosedByFiltered: Observable<string[]>;

  search1: any;

  referredByFiltered1: Observable<string[]>;
  referredBy1: Array<object> = [];
  medications: Array<object> = [];

  venueList: Array<object> = [
    {
      id: 1,
      name: 'Home'
    },
    {
      id: 2,
      name: 'OPD'
    },
    {
      id: 3,
      name: 'Hospital'
    },
    {
      id: 4,
      name: 'Clinic'
    }
  ];

  natureList: Array<object> = [
    {
      id: 1,
      name: 'Emergency'
    },
    {
      id: 2,
      name: 'Urgent'
    },
    {
      id: 3,
      name: 'General'
    }
  ];

  followUp: number;

  // For Save and Continue
  saveAndContinueClicked = false;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;
  followUpList: any[] = [
    {
      name: 'Yes',
      value: 1
    },
    {
      name: 'No',
      value: 2
    }
  ];
  diagnosisError = false;
  diagnosisErrMsg: '';
  medicationsError = false;
  medicationsErrMsg: '';
  backendValidationErrors: Array<any> = [
    {
      controlName: 'consultation_time_id',
      error: false
    }
  ];

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private urlService: UrlsService,
    private vitalService: VitalService,
    private diagnosisService: DiagnosisService,
    private medicationService: MedicationService,
    private encounterService: EncounterService,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil,
    private myAppointmentService: MyAppointmentService,
    private storageService: StorageService,
    private userService: UserServiceService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Update Encounter Information', this.patientId);
    });
    const loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
    this.loggedInUserId = loggedInUser.id;
    this.getSchedule();
    this.getBookedTimeList();
    this.getConsultationType();

    this.encounterId = +this.activatedRoute.snapshot.paramMap.get('encounterId');

    this.getPatientEncounter(this.patientId, this.encounterId);

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push(
            children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
          );
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });

    this.labReportService.getDoctorList().subscribe((y: any) => {
      this.doctors = y;
      this.filteredDoctors = this.setACField(this.encountersForm.controls.referred_by, 'doctors');
      this.referredBy1 = y;
      this.referredByFiltered1 = this.setACField(
        this.encountersForm.controls.referredBy1,
        'referredBy1'
      );
    });

    this.diagnosisService.getDiagnosisListAll(this.patientId).subscribe((y: any) => {
      this.diagnosisList = y.data;
      console.log(this.diagnosisList);
    });
    // get diagnosed by list from api and assign
    this.labReportService.getDoctorList().subscribe((y: any) => {
      this.diagnosedByList = y;
      this.diagnosedByFiltered = this.setACField(
        this.encountersForm.controls.diagnosed_by,
        'diagnosedByList'
      );
    });

    // get diagnosis center list from api and assign
    this.labReportService.getLabList().subscribe((y: any) => {
      this.diagnosisCenters = y.data.map(lab => lab.name);
      this.diagnosisCentresFiltered = this.setACField(
        this.encountersForm.controls.diagnosis_center,
        'diagnosisCenters'
      );
    });
  }

  getPatientEncounter(patientId, encounterId) {
    this.submitInProgress = true;
    this.encounterService.getPatientEncounter(patientId, encounterId).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.patientEncounter = response;
      },
      (error) => {
        this.submitInProgress = false;
        this.toastr.error('Sorry could not fetch encounter information at the moment!');
      },
      () => {
        const date = this.patientEncounter.follow_up_date;
        if (date) {
          this.getScheduleData(date);
        }
        this.encountersForm.patchValue({
          date: this.patientEncounter.date
            ? moment(this.patientEncounter.date).format('YYYY-MM-DD')
            : null,
          time: this.patientEncounter.date
            ? moment(this.patientEncounter.date).utc().format('h:mm A').toString()
            : null,
          venue: this.patientEncounter.venue,
          nature: this.patientEncounter.nature,
          referred_by: this.patientEncounter.referred_by,
          referredBy1: this.patientEncounter.encounter_medication.referred_by,
          chief_complaints: this.patientEncounter.chief_complaints,
          examination_details_subjective: this.patientEncounter.examination_details_subjective,
          examination_details_objective: this.patientEncounter.examination_details_objective,
          examination_details_assessment: this.patientEncounter.examination_details_assessment,
          examination_details_plan: this.patientEncounter.examination_details_plan,
          diagnosis_date: this.patientEncounter.encounter_diagnosis.date ? moment(this.patientEncounter.encounter_diagnosis.date).format(
            'YYYY-MM-DD'
          ) : null,
          diagnosed_by: this.patientEncounter.encounter_diagnosis.diagnosed_by,
          diagnosis_center: this.patientEncounter.encounter_diagnosis.diagnosis_center,
          clinical_note: this.patientEncounter.encounter_diagnosis.clinical_note,
          advice: this.patientEncounter.advice,
          drug_allergies: this.patientEncounter.drug_allergies,
          follow_up: this.patientEncounter.follow_up,
          follow_up_date: this.patientEncounter.follow_up_date
            ? moment(this.patientEncounter.follow_up_date).format('YYYY-MM-DD')
            : null,
          consultation_time_id: this.patientEncounter.consultation_booking
            ? this.patientEncounter.consultation_booking.consultation_time_id
            : null,
          consultation_type: this.patientEncounter.consultation_booking
            ? this.patientEncounter.consultation_booking.consultation_type
            : null,
          request_lab: this.patientEncounter.request_lab
        });
        this.selectedMedications = this.patientEncounter.encounter_medication.medications;
        this.followUp = this.patientEncounter.follow_up;
        this.setDiagnosisDefaults(this.patientEncounter.encounter_diagnosis.diagnosis);
        this.setVitalsDefaults(this.patientEncounter.encounter_vitals);
      }
    );
  }

  setVitalsDefaults(vitalList) {
    vitalList.map((vital) => {
      if (typeof vital.hasOwnProperty('rules') !== undefined) {
        const rulesMin = vital.rules.split('|')[0];
        const MinValue = rulesMin.split(':')[1];
        const rulesMax = vital.rules.split('|')[1];
        const MaxValue = rulesMax.split(':')[1];
        this.encountersForm.addControl(
          vital.label,
          new FormControl(vital.value, [
            Validators.min(this.transform.toNumber(MinValue)),
            Validators.max(this.transform.toNumber(MaxValue))
          ])
        );
      }
      if (vital.secondary_rules !== null) {
        const rulesMinSecondary = vital.secondary_rules.split('|')[0];
        const MinValueSecondary = rulesMinSecondary.split(':')[1];
        const rulesMaxSecondary = vital.secondary_rules.split('|')[1];
        const MaxValueSecondary = rulesMaxSecondary.split(':')[1];
        this.encountersForm.addControl(
          vital.secondary_label,
          new FormControl(vital.secondary_value, [
            Validators.min(this.transform.toNumber(MinValueSecondary)),
            Validators.max(this.transform.toNumber(MaxValueSecondary))
          ])
        );
      } else if (vital.secondary_label != null) {
        this.encountersForm.addControl(
          vital.secondary_label,
          new FormControl(vital.secondary_value)
        );
      }
      this.encountersForm.addControl(
        vital.uom_label.concat('_' + vital.label),
        new FormControl(vital.uom_selected)
      );
    });
    this.vitalList = vitalList;
  }

  setDiagnosisDefaults(diagnosisList: Array<any>) {
    diagnosisList.forEach((diagnosis: any) => {
      if (diagnosis.primary === 1) {
        this.primaryDiagnosis.push({
          id: diagnosis.id,
          name: diagnosis.name,
          primary: true,
          active: diagnosis.active === 1
        });
        this.d.push({
          id: diagnosis.id,
          name: diagnosis.name,
          primary: true,
          active: diagnosis.active === 1
        });
      } else {
        this.secondaryDiagnosis.push({
          id: diagnosis.id,
          name: diagnosis.name,
          primary: false,
          active: diagnosis.active === 1
        });
        this.d.push({
          id: diagnosis.id,
          name: diagnosis.name,
          primary: false,
          active: diagnosis.active === 1
        });
      }
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  addEncounters() {
    this.submitted = true;
    this.submitInProgress = true;
    if (this.encountersForm.invalid) {
      this.toastr.error(
        'Sorry, Encounters couldn\'t be updated at the moment because some fields are missing!'
      );
      this.submitInProgress = false;
      return;
    }

    this.medicationsError = false;
    this.diagnosisError = false;

    const date = this.encountersForm.controls.date.value;
    const time = this.encountersForm.controls.time.value;

    const data = this.encountersForm.value;

    data.date = this.getMergedDateTime(date, time);

    data.diagnosis = this.d;
    data.medications = this.selectedMedications;

    data.follow_up_date = this.patientEncounter.follow_up_date
      ? moment(this.patientEncounter.follow_up_date).format('YYYY-MM-DD')
      : null,
    this.encounterService.updatePatientEncounter(this.patientId, this.encounterId, data).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.submitInProgress = false;
        if (error.error[0]) {
          this.toastr.error(error.error[0]);
        } else {
          this.toastr.error(error.error.message);
        }
        if (error.status === 422) {
          const errors = error.error.errors;
          this.setBackendValidationErrors(errors);
          for (const err in error.error.errors) {
            console.log(error.error.errors[err]);
            if (err === 'diagnosis') {
              this.diagnosisError = true;
              this.diagnosisErrMsg = error.error.errors[err];
            } else if (err === 'medications') {
              this.medicationsError = true;
              this.medicationsErrMsg = error.error.errors[err];
            } else {
              this.encountersForm.controls[err].setErrors({
                invalid: true,
                msg: error.error.errors[err]
              });
            }
          }
        }
      },
      () => {
        this.submitInProgress = false;
        if (this.saveAndContinueClicked) {
          this.router.navigate(['/patients/' + this.patientId]);
        } else {
          this.router.navigate(['/patients/' + this.patientId + '/encounters']);
        }
        this.saveAndContinueClicked = false;
      }
    );
  }

  changeDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getMergedDateTime(date, time) {
    const utcDate = date ? moment.utc(date + ' ' + time, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD HH:mm:ss') : undefined;
    return utcDate;
  }

  followUpChange(event: MatRadioChange): void {
    this.patientEncounter.follow_up = event.value;
    this.followUp = event.value;
  }

  showSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.diagnosisService.getDiagnosisList(keyword, this.d).subscribe((x: any) => {
        console.log(x);
        this.arr = x;
        this.showDiagnosisSuggestion = true;
      });
    } else {
      this.showDiagnosisSuggestion = false;
    }
  }

  selectSuggestion(id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });
    x.active = true;
    if (this.primaryIsSelected === true) {
      x.primary = true;
      this.primaryDiagnosis.push(x);
    } else {
      x.primary = false;
      this.secondaryDiagnosis.push(x);
    }
    this.showDiagnosisSuggestion = false;
    this.search = '';
    this.d.push(x);
  }

  toggleDiagnosisActive(x) {
    if ('active' in x && x.active === true) {
      x.active = false;
    } else {
      x.active = true;
    }
    console.log(x);
  }

  togglePrimaryDiagnosis(e): void {
    this.primaryIsSelected = e.value === 'primary';
  }

  unSelectDiagnosis(x): void {
    // console.log(x);
    this.d = this.d.filter((y: any) => {
      return y.id !== x.id;
    });

    if (x.primary === true) {
      this.primaryDiagnosis = this.primaryDiagnosis.filter((y: any) => {
        return y.id !== x.id;
      });
    } else {
      this.secondaryDiagnosis = this.secondaryDiagnosis.filter((y: any) => {
        return y.id !== x.id;
      });
    }
  }

  showSuggestions1(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.medicationService.getMedicationList(keyword, this.selectedMedications).subscribe((x) => {
        this.medicationList = x;
        this.showMedicationSuggestion = true;
      });
    } else {
      this.showMedicationSuggestion = false;
    }
  }

  selectSuggestion1(id) {
    const x = this.medicationList.find((y) => {
      return y.id === id;
    });
    x.state = 'expanded';
    x.expandIcon = 'angle-up';
    x.expandMessage = 'Collapse Details';
    this.showMedicationSuggestion = false;
    this.search1 = '';
    this.selectedMedications.unshift(x);
  }

  setFrequency(e, x): void {
    x.frequency = e.target.value;
  }

  setDuration(e, x): void {
    x.duration = e.target.value;
  }

  setWayOfIntake(e, x): void {
    x.way_of_intake = e.target.value;
  }

  setUom(e, x): void {
    x.uom = e.target.value;
  }

  setDiagnosis(e, x): void {
    x.diagnosis = e.target.value;
    console.log(x);
  }

  deleteMedication(medication): void {
    this.selectedMedications = this.selectedMedications.filter((y) => {
      return y !== medication;
    });
  }

  toggleState(medication): void {
    medication.state = medication.state === 'collapsed' ? 'expanded' : 'collapsed';
    if (medication.state === 'expanded') {
      medication.expandIcon = 'angle-up';
      medication.expandMessage = 'Collapse Details';
    } else {
      medication.expandIcon = 'angle-down';
      medication.expandMessage = 'Expand Details';
    }
  }

  getScheduleData(date) {
    this.dayName = moment().weekday(moment(date).weekday()).format('dddd').toLowerCase();
    this.getScheduleId();
    this.getBookedId(date);
    this.getAvailableDate();
  }

  getAvailableDate() {
    this.bookedTimeId.forEach((bookedId) => {
      this.scheduleTimes = this.scheduleTimes.filter((scheduleTime) => {
        return scheduleTime.consultation_time_id !== bookedId;
      });
    });
  }

  getSchedule() {
    this.myAppointmentService.getScheduleTimeList(this.loggedInUserId).subscribe(
      (res: any) => {
        this.scheduleTimeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  getScheduleId() {
    this.scheduleTimes = [];
    this.scheduleTimeList.forEach((bookedTime) => {
      if (this.dayName === bookedTime.day) {
        this.scheduleTimes.push(bookedTime);
      }
    });
  }

  getBookedTimeList() {
    this.myAppointmentService.getBookedTimeList(this.loggedInUserId).subscribe(
      (res: any) => {
        this.bookedTimeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  getBookedId(date) {
    this.bookedTimeId = [];
    this.bookedTimeList.forEach((bookedTime) => {
      if (
        moment(date).format('YYYY-MM-DD') === bookedTime.date &&
        bookedTime.consultation_status === 'active'
      ) {
        this.bookedTimeId.push(bookedTime.consultation_time_id);
      }
    });
  }

  getConsultationType() {
    this.userService.getProfile().subscribe(
      (response: any) => {
        this.consultationTypeList = response.consultation_types;
      },
      (error) => {},
      () => {}
    );
  }

  setBackendValidationErrors(errors) {
    this.backendValidationErrors.forEach((keyObject) => {
      const errorArray  = errors[keyObject.controlName];
      const errorMessage = errorArray && errorArray.length > 0 ? errorArray[0] : '';
      keyObject.error = !!errorMessage;
      keyObject.message = errorMessage || '';
    });
  }
  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
