import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TestComponent } from './test/test.component';
import { AuthGuard } from './auth/shared/guards/auth.guard';
import { BusinessUnitComponent } from './add-business-unit/business-unit.component';
import { BusinessUnitListComponent } from './business-unit-list/business-unit-list.component';
import { AddRegionComponent } from './add-region/add-region.component';
import { RegionListComponent } from './region-list/region-list.component';
import { AddBusinessUnitLocationComponent } from './add-business-unit-location/add-business-unit-location.component';
import { BusinessUnitLocationListComponent } from './business-unit-location-list/business-unit-location-list.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LabBookingCreateComponent } from './feature/lab-bookings/lab-booking-create/lab-booking-create.component';
import { SampleCreateComponent } from './feature/lab-bookings/samples/sample-create/sample-create.component';
import { LabReportDetailComponent } from './feature/lab-bookings/lab-reports/lab-report-detail/lab-report-detail.component';
import { LabBookingDetailComponent } from './feature/lab-bookings/lab-booking-detail/lab-booking-detail.component';
import { LabBookingListComponent } from './feature/lab-bookings/lab-booking-list/lab-booking-list.component';
import { DeliveryCreateComponent } from './feature/lab-bookings/deliveries/delivery-create/delivery-create.component';
import { LabReportCreateComponent } from './feature/lab-bookings/lab-reports/lab-report-create/lab-report-create.component';
import { InvestigationCreateComponent } from './feature/investigations/investigation-create/investigation-create.component';
import { InvestigationListComponent } from './feature/investigations/investigation-list/investigation-list.component';
import { InvestigationEditComponent } from './feature/investigations/investigation-edit/investigation-edit.component';
import { LabBookingEditComponent } from './feature/lab-bookings/lab-booking-edit/lab-booking-edit.component';
import { LabDispatchCreateComponent } from './feature/lab-bookings/lab-dispatches/lab-dispatch-create/lab-dispatch-create.component';
import { LabPackageListComponent } from './feature/lab-packages/lab-package-list/lab-package-list.component';
import { LabPackageCreateComponent } from './feature/lab-packages/lab-package-create/lab-package-create.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { PrescriptionRefillListComponent } from './feature/prescription-refills/prescription-refill-list/prescription-refill-list.component';
import { ConsumerMessageListComponent } from './feature/consumer-messages/consumer-message-list/consumer-message-list.component';
import { MessageComponent } from './feature/message/message.component';
import { LabPackageDetailsComponent } from './feature/lab-packages/lab-package-details/lab-package-details.component';
import { VaccinationRefillsComponent } from './feature/vaccination-refills/vaccination-refills/vaccination-refills.component';
import { PhysiotherapyComponent } from './feature/physiotherapy/physiotherapy.component';
import { EmrSettingsListComponent } from './feature/emr-settings/emr-settings-list/emr-settings-list.component';
import { PaymentHistoryComponent } from './feature/payment-history/payment-history-list/payment-history.component';
import { InvestigationDetailComponent } from './feature/investigations/investigation-detail/investigation-detail.component';
import { PaymentHistoryDetailComponent } from './feature/payment-history/payment-history-detail/payment-history-detail.component';
import { LabReceivedCreateComponent } from './feature/lab-bookings/lab-received/lab-received-create/lab-received-create.component';
import { MyAppointmentListComponent } from './feature/my-appointment/my-appointment-list/my-appointment-list.component';
import { MyScheduleComponent } from './feature/my-appointment/my-schedule/my-schedule.component';
import { DoctorFeedbackComponent } from './feature/doctor-feedback/doctor-feedback.component';
import { InquiryFormComponent } from './inquiry-form/inquiry-form.component';
import { SubscriptionFormComponent } from './subscription-form/subscription-form.component';
import { InstantPayComponent } from './instant-pay/instant-pay.component';
import { DiagnosisCenterListComponent } from './feature/diagnosis-center/diagnosis-center-list/diagnosis-center-list.component';
import { ServiceManagementListComponent } from './feature/service-management/service-management-list/service-management-list.component';
import { ServiceManagementCreateComponent } from './feature/service-management/service-management-create/service-management-create.component';
import { ServiceManagementEditComponent } from './feature/service-management/service-management-edit/service-management-edit.component';
import { ServiceBookingsComponent } from './feature/service-bookings/service-bookings.component';
import { MyWorkSheetComponent } from './feature/my-work-sheet/my-work-sheet.component';
import { NurseWorksheetComponent } from './feature/nurse-worksheet/nurse-worksheet.component';
import { CallCenterComponent } from './feature/call-center/call-center-list/call-center.component';
import { CallCenterDetailComponent } from './feature/call-center/call-center-detail/call-center-detail/call-center-detail.component';
import { PhysiotherapyDetailComponent } from './feature/physiotherapy/physiotherapy-detail/physiotherapy-detail.component';
import { ServiceBookingDetailComponent } from './feature/service-bookings/service-booking-detail/service-booking-detail.component';
import { CallQueryListComponent } from './feature/call-queries/call-query-list/call-query-list.component';
import { CallQueryDetailComponent } from './feature/call-queries/call-query-detail/call-query-detail.component';
import { CallQueryCreateComponent } from './feature/call-queries/call-query-create/call-query-create.component';
import { PrescriptionRefillDetailComponent } from './feature/prescription-refills/prescription-refill-detail/prescription-refill-detail.component';
import { MedicationDetailComponent } from './feature/emr-settings/medication/medication-detail/medication-detail.component';
import { CallLogComponent } from './feature/call-log/call-log.component';
import { BannerFormComponent } from './banner/banner-form/banner-form.component';
import { BannerComponent } from './banner/banner.component';
import { LabReportsComponent } from './feature/lab-bookings/lab-reports/lab-report/lab-reports.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'inquiry-form',
    component: InquiryFormComponent
  },
  {
    path: 'subscription-form',
    component: SubscriptionFormComponent
  },
  {
    path: 'instant-pay',
    component: InstantPayComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'business-unit/create',
    component: BusinessUnitComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'business-units',
    component: BusinessUnitListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'region/create',
    component: AddRegionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regions',
    component: RegionListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'location/create',
    component: AddBusinessUnitLocationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'locations',
    component: BusinessUnitLocationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/create',
    component: LabBookingCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings',
    component: LabBookingListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/samples/create',
    component: SampleCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/lab-received',
    component: LabReceivedCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/lab-reports/create',
    component: LabReportCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/lab-reports',
    component: LabReportDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-report/:labReportId',
    component: LabReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId',
    component: LabBookingDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients',
    loadChildren: './patients/patients.module#PatientsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/deliveries/create',
    component: DeliveryCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'diagnostic-center/:diagnosticCenterId/investigations/create',
    component: InvestigationCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'diagnostic-center/:diagnosticCenterId/investigations/:investigationId/edit',
    component: InvestigationEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'diagnostic-center/:diagnosticCenterId/investigations/:investigationId',
    component: InvestigationDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/edit',
    component: LabBookingEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-bookings/:bookingId/lab-dispatches/create',
    component: LabDispatchCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-packages',
    component: LabPackageListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-packages/create',
    component: LabPackageCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-packages/:labPackageId',
    component: LabPackageDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lab-packages/:labPackageId/edit',
    component: LabPackageCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'physiotherapy',
    component: PhysiotherapyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'physiotherapy/:physiotherapyId',
    component: PhysiotherapyDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'diagnostic-center',
    component: DiagnosisCenterListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'diagnostic-center/:diagnosticCenterId',
    component: InvestigationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'video/:call',
    loadChildren: './video-chat/video-chat.module#VideoChatModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'profiles',
    loadChildren: () => import('./profiles/profiles.module').then((m) => m.ProfilesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then((m) => m.PasswordModule)
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then((m) => m.RegistrationModule)
  },
  {
    path: 'call',
    loadChildren: () =>
      import('./twilio-video/twilio-video.module').then((m) => m.TwilioVideoModule)
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'prescription-refills',
    component: PrescriptionRefillListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'prescription-refills/:id',
    component: PrescriptionRefillDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vaccination-refills',
    component: VaccinationRefillsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'emr-settings',
    component: EmrSettingsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'consumer-messages',
    component: ConsumerMessageListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    component: MessageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/history',
    component: PaymentHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-appointment',
    component: MyAppointmentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'nurse-appointment',
    component: MyAppointmentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-schedule',
    component: MyScheduleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/history/:invoiceId',
    component: PaymentHistoryDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'doctor-feedback',
    component: DoctorFeedbackComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service-management',
    component: ServiceManagementListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service-management/create',
    component: ServiceManagementCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service-management/:id/edit',
    component: ServiceManagementEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service-booking',
    component: ServiceBookingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service-bookings/:id',
    component: ServiceBookingDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-worksheet',
    component: MyWorkSheetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'worksheets',
    component: NurseWorksheetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-us',
    component: CallCenterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-us/:id',
    component: CallCenterDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'call-query',
    component: CallQueryListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'call-query/create',
    component: CallQueryCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'call-query/:id',
    component: CallQueryDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'medication-list/:id',
    component: MedicationDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'banner',
    component: BannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'banner/create',
    component: BannerFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'call-log',
    component: CallLogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  },
  { path: 'test', component: TestComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
