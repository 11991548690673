import { Component, Input, OnInit } from '@angular/core';
import { ServiceTypeListService } from '../../../../services/service-type-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ServiceTypeFormComponent } from '../service-type-form/service-type-form.component';
import { FormDataUtil } from '../../../../shared/utils/formData/form-data-util';

@Component({
  selector: 'app-service-type-list',
  templateUrl: './service-type-list.component.html',
  styleUrls: ['./service-type-list.component.css']
})
export class ServiceTypeListComponent implements OnInit {
  @Input() clearUrl: boolean;
  serviceTypeList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  serviceTypeSearchQuery: string;

  constructor(
    private serviceTypeService: ServiceTypeListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const serviceTypeSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.serviceTypeSearchQuery = serviceTypeSearchQuery ? serviceTypeSearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getServiceType();
  }

  openModal(serviceType) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '300px';
    dialogConfig.width = '300px';
    dialogConfig.data = serviceType;

    const dialogRef = this.dialog.open(ServiceTypeFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getServiceType();
      }
    });
  }

  getServiceType(): void {
    const page = this.page;
    this.loading = true;
    const data = FormDataUtil.filterEmptyParams({
      pageNo: this.page,
      limit: this.limit,
      q: this.serviceTypeSearchQuery
    });

    this.serviceTypeService.getServiceTypeList(data).subscribe(
      (response: any) => {
        console.log(response.data);
        this.serviceTypeList = response.data;
        this.total = response.size;
      },
      (error) => {
        this.loading = false;
        this.serviceTypeList.length = 0;
      },
      () => {
        this.loading = false;
      }
    );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchServiceType();
  }

  searchServiceType() {
    this.getServiceType();
    this.addParamsToUrl(this.page, this.limit, this.serviceTypeSearchQuery);
  }

  clearSearch() {
    this.serviceTypeList = null;
    this.page = 1;
    this.limit = 10;
    this.serviceTypeSearchQuery = null;
    this.searchServiceType();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getServiceType();
    this.addParamsToUrl(this.page, this.limit, this.serviceTypeSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getServiceType();
    this.addParamsToUrl(this.page, this.limit, this.serviceTypeSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getServiceType();
    this.addParamsToUrl(this.page, this.limit, this.serviceTypeSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
