import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ToastMessageService } from 'src/app/shared/services/toast-message-service/toast-message.service';
import { SharedConstant } from '../../../shared/constant/shared-constant';
import { Observable, of, Subject } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
import { Router } from '@angular/router';
import ConnectyCube from '../../../../assets/connecty-cube-js/connectycube.min.js';
import {flatMap, map} from 'rxjs/operators';
import { SocketService } from '../../../services/socket.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  userAuthEmitter: EventEmitter<boolean> = new EventEmitter();
  userAuthorized = false;
  private apiUrl = environment.apiUrl; // URL to web api
  private appUrl = environment.appUrl; // URL to web app
  private VoIPAppConfig = environment.VoIPAppConfig;
  private VoIPCredentials = environment.VoIPCredentials;
  private grantType = environment.grantType;
  private grantTypeRefreshToken = 'refresh_token';
  private requestFrom = 'portal';
  private clientId = environment.clientId;
  private clientSecret = environment.clientSecret;
  private scope = environment.scope;
  private VoIPLoginStatus = false;
  private isServiceInit: boolean;
  public checkingRefreshToken = false;
  sharedConstant = SharedConstant;
  public connectyCubeClient: any;
  public loggingOut = false;
  public showHeader = true;
  public showSidebarMenu = true;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private refreshTokenSubject: Subject<boolean>;
  refreshTokenData: boolean;

  constructor(
    private http: HttpClient,
    private toastMessageService: ToastMessageService,
    private storageService: StorageService,
    public router: Router,
    private socketService: SocketService
  ) {
    this.connectyCubeClient = ConnectyCube;
  }

  ngOnInit() {
    this.refreshTokenSubject.subscribe((data) => {
      this.refreshTokenData = data;
    });
  }

  public isAuthenticated(): Observable<boolean> {
    let token = this.storageService.getAPIToken();
    if (token) {
      if (!this.hasTokenExpired(token)) {
        return of(true);
      }
      if (!this.checkingRefreshToken) {
        this.checkingRefreshToken = true;
        this.refreshToken(JSON.parse(token).refresh_token);
      }
      return of(this.refreshTokenData);
    }
    return of(false);
  }
  private refreshToken(refreshToken: string) {
    this.sendRefreshTokenRequest(refreshToken).subscribe(
      (token) => {
        this.checkingRefreshToken = false;
        this.refreshTokenSubject.next(true);
        this.storageService.setAPIToken(JSON.stringify(this.getTokenObject(token)));
      },
      (error) => {
        this.checkingRefreshToken = false;
        this.refreshTokenSubject.next(false);
        console.error('RefreshError', error);
        this.storageService.clear();
        this.VoIPLogout();
      }
    );
  }

  sendLoginRequest(data) {
    const url = this.appUrl + `oauth/token`;
    data.grant_type = this.grantType;
    data.client_id = this.clientId;
    data.client_secret = this.clientSecret;
    data.scope = this.scope;
    data.request_from = this.requestFrom;
    return this.http.post(url, data, this.httpOptions).pipe(
      map((res) => {
        this.storageService.setAPIToken(JSON.stringify(this.getTokenObject(res)));
        this.socketService.setUpSocketConnection();
        this.socketService.connect();
        return res;
      })
    );
  }

  sentDeleteAccountRequest(payload: { username: string; password: string }) {
    const data: Record<string, any> = payload;
    data.grant_type = this.grantType;
    data.client_id = this.clientId;
    data.client_secret = this.clientSecret;
    data.scope = this.scope;
    data.request_from = this.requestFrom;
    return this.http.post(`${this.appUrl}oauth/token`, data, this.httpOptions).pipe(
      flatMap((res: any) => {
        return this.http.delete(`${this.apiUrl}users/delete-account`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${res.access_token}`
          })
        });
      })
    );
  }

  sendLogOutRequest() {
    const url = this.apiUrl + `logout`;
    return this.http.post(url, {}, this.httpOptions).pipe(
      map((res) => {
        this.socketService.disconnect();
        return res;
      })
    );
  }

  getConnectyCubeUserDetails(): Observable<any> {
    return this.http.get(this.apiUrl + 'connectycube-login');
  }

  hasTokenExpired(token) {
    const objectToken = JSON.parse(token);
    const tokenExpiryDate = new Date(objectToken.expiresAt);
    const nowDate = new Date();
    return nowDate.getTime() >= tokenExpiryDate.getTime();
  }

  VoIPInit = () => {
    this.connectyCubeClient.init(this.VoIPCredentials, this.VoIPAppConfig);
    this.connectyCubeClient.chat.onDisconnectedListener = () => {
      this.userAuthEmitter.emit(false);
    };
    this.connectyCubeClient.chat.onReconnectListener = () => {
      this.userAuthEmitter.emit(true);
    };
  };

  VoIPLogin = (user) => {
    return new Promise<void>((resolve, reject) => {
      this.connectyCubeClient
        .createSession(user)
        .then(() =>
          this.connectyCubeClient.chat.connect({ userId: user.id, password: user.password })
        )
        .then(() => {
          this.userAuthorized = true;
          this.userAuthEmitter.emit(this.userAuthorized);
          //this.toastMessageService.setMessage(this.sharedConstant.info, 'user auth complete');
          resolve();
        })
        .catch('reject');
    });
  };

  VoIPLogout = () => {
    if (this.connectyCubeClient.chat) {
      this.connectyCubeClient.chat.disconnect();
      this.connectyCubeClient.destroySession();
    }
  };

  setVoIPLoginStatus(status: boolean): void {
    this.VoIPLoginStatus = status;
  }

  isVoIPLoginSuccess(): boolean {
    return this.VoIPLoginStatus;
  }

  sendRefreshTokenRequest(refreshToken) {
    const url = this.appUrl + `oauth/token`;
    const data: any = {};
    (data.refresh_token = refreshToken), (data.grant_type = this.grantTypeRefreshToken);
    data.client_id = this.clientId;
    data.client_secret = this.clientSecret;
    data.scope = this.scope;

    return this.http.post(url, data, this.httpOptions);
  }

  getTokenObject(responseObject) {
    return {
      expiresAt: this.getExpiryDate(responseObject.expires_in),
      token_type: responseObject.token_type,
      access_token: responseObject.access_token,
      refresh_token: responseObject.refresh_token
    };
  }

  getExpiryDate(tokenExpiresIn) {
    const dateTime = new Date();
    dateTime.setSeconds(dateTime.getSeconds() + tokenExpiresIn);
    return dateTime;
  }
}
