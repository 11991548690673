import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BusinessUnitLocationService } from '../services/business-unit-location.service';
import { MatSnackBar } from '@angular/material';
import { NotifyService } from '../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-business-unit-location',
  templateUrl: './add-business-unit-location.component.html',
  styleUrls: ['./add-business-unit-location.component.css']
})
export class AddBusinessUnitLocationComponent implements OnInit {
  locationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    mobile: new FormControl('', [Validators.required]),
    fax: new FormControl(''),
    contact_person: new FormControl(''),
    email: new FormControl('', [Validators.required])
  });

  // For Form Footer
  cancelUrl = '/locations';
  submitInProgress = false;

  constructor(
    private businessUnitLocationService: BusinessUnitLocationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  store() {
    this.submitInProgress = true;
    const locationData = this.locationForm.value;
    if (this.locationForm.status === 'VALID') {
      this.businessUnitLocationService.store(locationData).subscribe(
        (response: any) => {
          this.toastr.success('Location created Successfully!');
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error("Couldn't create location at the moment!");
        },
        () => {
          this.submitInProgress = false;
          this.locationForm.reset();
        }
      );
    } else {
      this.submitInProgress = false;
      console.log('Form validation failed');
    }
  }

  makePristine(targetValue, controlField) {
    const control = this.locationForm.controls[controlField];
    if (targetValue === '' || targetValue === null) {
      control.reset();
      control.markAsPristine();
    }
  }
}
